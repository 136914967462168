<template>
    <div class="store">
        <el-card>
            <template #header>
                <span>店铺列表</span>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-select v-model="merchantid" size="small" style="margin-right:20px;width: 100px;" v-if="ismerchant != 1">
                        <el-option v-for="item in merchantlist" :key='item' :label="item.Merchant" :value="item.ID"></el-option>
                    </el-select>
                    <el-input size="small" v-model="keyword" style="width:250px;margin-right:20px;" placeholder="请输入店铺名称/店铺编号"></el-input>
                    <el-date-picker v-model="range" 
                        type="daterange" 
                        placeholder="请选择日期" 
                         
                        unlink-panels 
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        size="small"
                        style="margin-right:20px;">
                    </el-date-picker>
                    <el-button @click="init" size="small" type="primary">搜索</el-button>
                    <el-button @click="add" size="small" type="success">新增</el-button>
                </div>
                <el-table :data="tabledata"
                        border style="width: 100%; margin: 1em 0;"
                        v-loading="load"
                        size="small"
                        element-loading-spinner="el-icon-loading" 
                        element-loading-text="加载中...">
                    <el-table-column label="商户名称" prop="Merchant" v-if="ismerchant != 1"></el-table-column>
                    <el-table-column label="店铺编号" prop="StoreCode"></el-table-column>
                    <el-table-column label="店铺名称" prop="Store"></el-table-column>
                    <el-table-column label="设备总数/在线" prop="DeviceCount">
                        <template #default='scope'>
                            <span v-if="scope.row.TotalDeviceCount">{{scope.row.TotalDeviceCount}}</span>
                            <span v-else>0</span>台 / 
                            <span v-if="scope.row.Enable">{{scope.row.Enable}}</span>
                            <span v-else>0</span>台
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" prop="AddDTime">
                        <template #default='scope'>
                            <span>{{formatDate(scope.row.AddDTime)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="更新时间" prop="UpdateDTime">
                        <template #default='scope'>
                            <span>{{formatDate(scope.row.UpdateDTime)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单数" prop="OrderCount">
                        <template #default='scope'>
                            <span v-if="scope.row.OrderCount">{{scope.row.OrderCount}}</span>
                            <span v-else>0</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单总金额" prop="TotalOrderMoney">
                        <template #default='scope'>
                            <span v-if="scope.row.TotalOrderMoney">{{scope.row.TotalOrderMoney/100}}</span>
                            <span v-else>0</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default='scope'>
                            <el-button size="small" @click="Edit(scope.row.ID)" type="text">编辑</el-button>
                            
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                
                                layout="prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="addstore" title="编辑店铺资料" width="40%" center>
        <el-form :model="storeinfo" label-width="150px" ref="storeinfo">
            <el-form-item label="所属商户" :rules="{required:'true',message:'请选择所属商户',trigger:'blur'}" prop="MerchantID" v-if="ismerchant != 1">
                 <el-select v-model="storeinfo.MerchantID" @change="ChangeDevice">
                     <el-option v-for="item in merchantlist" :key="item" :label="item.Merchant" :value="item.ID"></el-option>
                 </el-select>
            </el-form-item>
            <el-form-item label="店铺名称" prop="Store" :rules="{required:'true',message:'请填写店铺名称',trigger:'blur'}">
                <el-input v-model="storeinfo.Store"></el-input>
            </el-form-item>
            <el-form-item label="美团店铺" prop="OpenShopUUID">
                <el-select v-model="storeinfo.OpenShopUUID" @change="show">
                    <!-- {{ shoplist }} -->
                    <el-option v-for="item in shoplist" :key="item" :value="item.OpenShopUUID" :label="item.ShopName + ' - ' + item.Address + ' - ' + item.City"></el-option>
                </el-select>
                <el-alert title="*如遇【非当前门店】请检查该选项是否匹配店铺信息" type="warning" :closable="false" style="margin-top:10px;" show-icon></el-alert>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div class="dialoginfo">
            <el-transfer v-model="storeinfo.devicelist" filterable 
                :titles='transfertitle'
                :data='storeinfo.devices'
                ></el-transfer>
        </div>
        <el-divider></el-divider>
        <template #footer>
            <el-button @click="save" type="primary" size="small">确定</el-button>
            <el-button @click="cancel" size="small">取消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import Cookies from 'js-cookie'
import qs from "qs"
import constant from "@/constant"
export default {
    data(){
        return {
            shoplist:[],
            ismerchant:'',
            mid:'',
            tabledata:[],
            merchantid:'',
            merchantlist:[],
            addstore:false,
            range:[],
            keyword:'',
            totalcount:'',
            curpage:1,
            pagesize:10,
            transfertitle:['全部设备','已选设备'],
            storeinfo:{
                ID:'',
                Store:'',
                MerchantID:'',
                devicelist:[],
                devices:[],
                OpenShopUUID:''
            },
            
        }
    },
    methods:{
        show(val){
            console.log(val);
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        ChangeDevice(val){
            this.GetShop(val);
            this.axios.get(constant.changedevice,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    merchantid: val
                }
            }).then((response)=>{
                console.log(response.data);
                this.storeinfo.devices = response.data;
                
            });
                
            
        },
        init(){
            this.axios.get(constant.storelist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    keyword:this.keyword,
                    range:JSON.stringify(this.range),
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    merchantid:this.merchantid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage;
                this.totalcount = response.data.totalcount*1;
            });
        },
        add(){
            this.addstore = true;
            this.storeinfo = {
                ID:'',
                Store:'',
                MerchantID:'',
                devicelist:[],
                devices:[],
                OpenShopUUID:''
            }
        },
        save(){
            this.$refs['storeinfo'].validate((valid)=>{
                if(valid){
                    console.log(this.storeinfo);
                    this.axios.post(constant.savestore,qs.stringify(this.storeinfo),{
                        headers:{
                            "content-type":"application/x-www-form-urlencoded"
                        },
                        params:{
                            opid:this.opid,
                            role:this.role
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        }else{
                            this.$message.error("操作失败");
                        }
                    });
                }
            });
        },
        cancel(){
            this.addstore = false;
        },
        formatDate(date){
            var datetime = new Date(date*1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            return year + "-" + month + "-" + day;
        },
        Edit(id){
            this.axios.get(constant.getstore,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.storeinfo = response.data;
                this.GetShop(this.storeinfo.MerchantID);
                this.addstore = true;
            });
        },
        GetShop(merchantid){
            this.axios.get(constant.getshoplist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    merchantid:merchantid
                }
            }).then((response)=>{
                console.log("result");
                console.log(response.data);
                this.shoplist = response.data;
            });
        }
        
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.opid = Cookies.get("id");
        this.role = Cookies.get("role");
        this.init();
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            },
        }).then((response)=>{
            console.log(response.data);
            this.merchantlist = response.data;
        });
        
        
    }
}
</script>
<style scoped>
.el-alert {
    padding: 0;
}
.dialoginfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}
</style>